import classActions from './actions/classActions';
import courseActions from './actions/courseActions';
import pathwayActions from './actions/pathwayActions';
import pathwayCohortActions from './actions/pathwayCohortActions';
import profileActions from './actions/profileActions';
import courseSectionActions from './actions/courseSectionActions';
import sessionActions from './actions/sessionActions';
import complianceActions from '@/store/roster/actions/complianceActions';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ...classActions,
    ...courseActions,
    ...courseSectionActions,
    ...pathwayActions,
    ...pathwayCohortActions,
    ...profileActions,
    ...sessionActions,
    ...complianceActions,
  },
};
