import axios from '@/libs/axios';

const createSectionComplianceReports = (ctx, { sectionId, mode }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/roster/compliance-reports/section', {
        sectionId,
        mode: mode || 'final',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  createSectionComplianceReports,
};
