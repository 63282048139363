import axios from '@axios';

const fetchCourseSectionList = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/sections', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSectionListLive = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/sections/live', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSectionListUpcoming = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/sections/upcoming', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSection = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/sections/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSectionRoster = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/sections/${id}/export`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSectionSummary = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/sections/${id}/summary`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSectionAttendanceTable = (ctx, { id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/attendances/section/${id}/table`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const fetchCourseSectionStudents = (ctx, { courseSectionId, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/sections/${courseSectionId}/students`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchCourseSectionClasses = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/sections/${id}/classes`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateCourseSection = (ctx, { courseSectionId, courseSectionData }) => {
  // Sanitize the request body before sending it the server to prevent strict validation from killing the request
  if (courseSectionData && courseSectionData.id) {
    delete courseSectionData.id;
  }
  if (courseSectionData && courseSectionData.createdAt) {
    delete courseSectionData.createdAt;
  }
  if (courseSectionData && courseSectionData.updatedAt) {
    delete courseSectionData.updatedAt;
  }

  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/sections/${courseSectionId}`, courseSectionData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateCourseSectionCompliance = (ctx, { courseSectionId, complianceData }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/sections/${courseSectionId}/compliance`, complianceData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const syncCourseSectionCompliance = (ctx, { sectionId }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/sections/${sectionId}/compliance`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const sendCourseSectionBroadcast = (ctx, { courseSectionId, broadcast }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/sections/${courseSectionId}/broadcast`, broadcast)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const createCourseSection = (ctx, { courseSectionData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/roster/sections', courseSectionData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

/*
 * {sectionId: string, profileIds: string[], options: {replaces?:String}}
 */
const addStudentsToSection = (ctx, { sectionId, profileIds, options }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/sections/${sectionId}/students`, { profileIds, options })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const removeStudentsFromSection = (ctx, { sectionId, profileIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/roster/sections/${sectionId}/students`, { data: { studentIds, profileIds } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const fetchCourseCohort = (ctx, { cohortId }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/courses/-/cohorts/${cohortId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const addStudentsToCourseCohort = (ctx, { cohortId, profileIds, studentIds, metadata }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/courses/-/cohorts/${cohortId}/students`, { profileIds, studentIds, metadata })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const removeStudentsFromCourseCohort = (ctx, { cohortId, studentIds, profileIds }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v2/roster/courses/-/cohorts/${cohortId}/students`, { data: { studentIds, profileIds } })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const exportSectionRoster = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/sections/${id}/export`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const exportSectionCompliance = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/roster/sections/${id}/export-compliance`, {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const sendOnboardingToSection = (ctx, { sectionId }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/roster/sections/${sectionId}/myip-onboarding`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default {
  addStudentsToCourseCohort,
  removeStudentsFromCourseCohort,
  fetchCourseCohort,
  fetchCourseSectionList,
  fetchCourseSectionListLive,
  fetchCourseSectionListUpcoming,
  fetchCourseSection,
  fetchCourseSectionRoster,
  fetchCourseSectionSummary,
  fetchCourseSectionAttendanceTable,
  fetchCourseSectionStudents,
  fetchCourseSectionClasses,
  updateCourseSection,
  updateCourseSectionCompliance,
  syncCourseSectionCompliance,
  sendCourseSectionBroadcast,
  createCourseSection,
  exportSectionRoster,
  exportSectionCompliance,
  addStudentsToSection,
  removeStudentsFromSection,
  sendOnboardingToSection,
};
